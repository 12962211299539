let $stage;
const tmpl = {};
const viewState = {
	prevState: null,
	currentState: null,
	history: [],
};

const categories = {
	CANCEL_OR_REFUND: {
		label: "Cancellation or refunds",
		subCategories: {
			CANCEL_POLICY: {
				nextAction: "SHOW_EVENT_CANCELLATION_POLICY", //special handling
				label: "What is the cancel policy?",
				messageTo: "ORG",
			},
			HOW_TO_CANCEL: {
				label: "How to cancel",
				articleLink: "https://support.motorsportreg.com/how-do-i-cancel-my-registration-1",
				messageTo: "ORG",
			},
			RECEIVE_REFUND: {
				label: "When will I receive my refund?",
				articleLink: "https://support.motorsportreg.com/how-do-i-get-a-refund",
				messageTo: "ORG",
			},
			OTHER: {
				label: "Something else",
				messageTo: "ORG",
			},
		},
	},
	PAYMENTS: {
		label: "Payments",
		subCategories: {
			CARD_WONT_WORK: {
				label: "Credit card won&apos;t go through",
				articleLink: "https://support.motorsportreg.com/why-is-my-credit-card-zip-or-postal-code-not-matching",
				messageTo: "ORG",
			},
			UPDATE_PAYMENT_INFO: {
				label: "Update or change credit card",
				articleLink: "https://support.motorsportreg.com/how-do-i-update-my-credit-card",
				messageTo: "ORG",
			},
			DUPLICATE_CHARGE: {
				label: "Duplicate charges",
				articleLink: "https://support.motorsportreg.com/i-think-i-was-double-billed-how-do-i-check",
				messageTo: "ORG",
			},
			OTHER: {
				label: "Something else",
				messageTo: "ORG",
			},
		},
	},
	ACCOUNT_RELATED: {
		label: "Account related",
		subCategories: {
			CANT_LOGIN: {
				label: "Can&apos;t log in or email has changed",
				articleLink: " https://support.motorsportreg.com/how-do-i-reset-my-password",
				messageTo: "ORG",
				conditions: { isLoggedIn: false },
			},
			FORGOT_PASSWORD: {
				label: "Forgotten password",
				articleLink: "https://support.motorsportreg.com/how-do-i-reset-my-password",
				messageTo: "ORG",
				conditions: { isLoggedIn: false },
			},
			CHANGE_PASSWORD: {
				label: "Change password",
				articleLink: "https://support.motorsportreg.com/how-do-i-change-my-password",
				messageTo: "ORG",
				conditions: { isLoggedIn: true },
			},
			UPDATE_ADDRESS: {
				label: "Update address",
				articleLink: "https://support.motorsportreg.com/how-to-update-account-info-address-zip-emergency-contact-email",
				messageTo: "ORG",
				conditions: { isLoggedIn: true },
			},
			REGISTER_SOMEONE_ELSE: {
				label: "Register someone else",
				articleLink: "https://support.motorsportreg.com/how-to-register-someone-else",
				messageTo: "ORG",
			},
			ADD_EDIT_VEHICLE: {
				label: "Add or edit vehicles",
				articleLink: "https://support.motorsportreg.com/how-do-i-change-the-vehicle-in-my-registration",
				messageTo: "ORG",
			},
			OTHER: {
				label: "Something else",
				messageTo: "ORG",
			},
		},
	},
	EVENT_OR_ORG: {
		label: "A question about this event or organization",
		messageTo: "ORG",
	},
	TECH_SUPPORT: {
		label: "A computer question for MotorsportReg",
		messageTo: "MSR",
	},
};

const greetingData = {
	isLoggedIn: PAGE.user.isLoggedIn,
	vchFirstName: PAGE.user.vchFirstName,
	vchLastName: PAGE.user.vchLastName,
	vchEmail: PAGE.user.vchEmail,
	uidMember: PAGE.user.uidMember,
	homePhone: PAGE.user.homePhone,
	workPhone: PAGE.user.workPhone,
	mobilePhone: PAGE.user.mobilePhone,
};

let supportData = null;
let eventData = {};
let selectedProfile = {
	...greetingData,
};
let supportFlowSessionId = new Date().getTime();
let includeMsrOption = false;
let launchLink = '';

/*
========================================================================================================================
Utility Functions
========================================================================================================================
 */

function storeCurrentEvent() {
	if (msr.isLocalStorageAvailable() && PAGE.event) {
		let eventStack = JSON.parse(localStorage.getItem("support_flow-event_stack")) || [];

		//if this event is not the latest event already, add it
		if (eventStack.length === 0 || eventStack[eventStack.length - 1].uidEvent !== PAGE.event.uidEvent) {
			eventStack.push(PAGE.event);
		}

		// keep no more than the last 10
		eventStack.splice(0, eventStack.length - 9);
		localStorage.setItem("support_flow-event_stack", JSON.stringify(eventStack));
	}
}

function getFilteredSubCategories(selectedCategory, isLoggedIn) {
	const subCategories = getCategoryData(selectedCategory)["subCategories"] || [];
	return _.pickBy(subCategories, function(subCategory) {
		if (subCategory.hasOwnProperty("conditions")) {
			return _.every(subCategory["conditions"], function(value, key) {
				if (key === "isLoggedIn") {
					return isLoggedIn === value;
				} else {
					console.error("Unhandled Condition: ", key, value);
				}
			});
		}

		return true;
	});
}

function getCategoryData(selectedCategory) {
	return categories[selectedCategory] || [];
}

function getSubCategoryData(selectedCategory, selectedSubCategory) {
	const selectedCategoryData = getCategoryData(selectedCategory);
	const subCategories = selectedCategoryData["subCategories"] || [];
	return subCategories[selectedSubCategory];
}

function getEventContextOnPage() {
	if (PAGE.event) {
		return PAGE.event;
	}
	return undefined;
}

function fireSupportFunnelEvent(action, data) {
	jq2(document).trigger("support_funnel", [action, data]);
}

function handleSupportFunnelEvent(e, action, data) {
	data = {
		...data,
		supportFlowSessionId,
	};
	msr.analyticsLogEvent({
		vchCategory: "support_funnel",
		vchAction: action,
		jsnData: JSON.stringify(data),
		dtmClientTimestamp: new Date().toISOString(),
		gidRequestID: PAGE.requestID,
		uidParty: PAGE.user.uidMember,
	});
}

function getLatestEventsViewed() {
	/*	return (up to) the last three unique events viewed	 */
	if (msr.isLocalStorageAvailable()) {
		const eventStack = JSON.parse(localStorage.getItem("support_flow-event_stack")) || [];
		eventStack.reverse();
		return eventStack.reduce(function(agg, event) {
			if (agg.length >= 3) {
				return agg;
			}
			if (
				!_.includes(agg, function(aggEvent) {
					return aggEvent.uidEvent === event.uidEvent;
				})
			) {
				agg.push(event);
			}
			return agg;
		}, []);
	} else if (PAGE.event) {
		return [PAGE.event];
	}
	return [];
}

window.getLatestEventsViewed = getLatestEventsViewed;

function compileTemplate(id) {
	return _.template(document.getElementById(id).innerText, {
		imports: { tmpl },
	});
}

function changeView($stage, newContent, stateName, data) {
	$stage.empty().append(newContent);

	viewState.prevState = viewState.currentState;
	viewState.currentState = stateName;
	viewState.history.push([viewState.prevState, viewState.currentState, data]);

	//console.log("support_funnel-change_view", viewState.prevState, " --> ", viewState.currentState, " : ", data);
}

function getEventData(uidEvent, callback) {
	if (eventData.hasOwnProperty(uidEvent)) {
		//console.log("eventData from cache", uidEvent, eventData[uidEvent]);
		if (_.isFunction(callback)) {
			return callback(eventData[uidEvent]);
		}
	} else {
		jq2.ajax({
			url: PAGE.xe.eventData,
			data: { uidEvent },
			dataType: "json",
			method: "GET",
			success: function(result) {
				if (result.success) {
					eventData[uidEvent] = result.data;

					if (_.isFunction(callback)) {
						callback(eventData[uidEvent]);
					}
				} else {
					if (_.isFunction(callback)) {
						callback(undefined);
					}
				}
			},
			error: function(error) {
				console.error("something went wrong", error);
			},
			complete: function(data) {
				//console.log("complete", data);
			},
		});
	}
}

function supportFunnelSubmit(data, successCallback, errorCallback) {
	jq2.ajax({
		url: PAGE.xe.supportFunnelSubmit,
		data: data,
		dataType: "json",
		method: "POST",
		success: function(result) {
			if (result.success) {
				if (_.isFunction(successCallback)) {
					successCallback(result.data);
				}
			} else {
				if (_.isFunction(errorCallback)) {
					errorCallback(result.errors);
				}
			}
		},
		error: function(error) {
			console.error("something went wrong", error);
		},
		complete: function(data) {
			//console.log("complete", data);
		},
	});
}

function getSupportData(callback) {
	if (!_.isNull(supportData)) {
		if (_.isFunction(callback)) {
			return callback(supportData);
		}
	} else {
		if (PAGE.user.isLoggedIn) {
			jq2.ajax({
				url: PAGE.xe.supportData,
				data: {},
				dataType: "json",
				method: "GET",
				success: function(result) {
					supportData = result.data;

					const latestEvents = getLatestEventsViewed();

					latestEvents.forEach(function(latestEvent) {
						//need to check if this event is already in the data we got for the user
						if (!_.find(supportData.events, event => event.uidEvent === latestEvent.uidEvent)) {
							supportData.events.push(latestEvent);
						}
					});

					supportData.events = supportData.events.map(function(event) {
						event.label = eventLabel(event);
						return event;
					});

					//group the events
					supportData.groupedEvents = {
						PAST: supportData.events.filter(event => event.eventPeriod === "PAST"),
						CURRENT: supportData.events.filter(event => event.eventPeriod === "CURRENT"),
						FUTURE: supportData.events.filter(event => event.eventPeriod === "FUTURE"),
					};

					if (latestEvents.length) {
						supportData.selectedEvent = _.find(supportData.events,
							event => event.uidEvent === _.first(latestEvents).uidEvent,
						);
					} else if (!supportData.selectedEvent) {
						//pull the earliest current, future, past - in that order
						if (supportData.groupedEvents["CURRENT"].length) {
							supportData.selectedEvent = supportData.groupedEvents["CURRENT"][0];
						} else if (supportData.groupedEvents["FUTURE"].length) {
							supportData.selectedEvent = supportData.groupedEvents["FUTURE"][0];
						} else if (supportData.groupedEvents["PAST"].length) {
							supportData.selectedEvent = supportData.groupedEvents["PAST"][0];
						} else {
							//leave it undefined, the render process will notice no selectedEvent and will
							//render the input field instead.
						}
					}

					if (_.isFunction(callback)) {
						callback(supportData);
					}
				},
				error: function(error) {
					console.error("something went wrong", error);
				},
				complete: function(data) {
					//console.log("complete", data);
				},
			});
		} else {
			//not logged in, so there wont be any response
			supportData = {
				profiles: [],
				events: [],
				selectedEvent: undefined,
			};

			const latestEvents = getLatestEventsViewed();

			latestEvents.forEach(function(latestEvent) {
				supportData.events.push(latestEvent);
			});

			supportData.events = supportData.events.map(function(event) {
				event.label = eventLabel(event);
				return event;
			});

			//group the events
			supportData.groupedEvents = {
				PAST: supportData.events.filter(event => event.eventPeriod === "PAST"),
				CURRENT: supportData.events.filter(event => event.eventPeriod === "CURRENT"),
				FUTURE: supportData.events.filter(event => event.eventPeriod === "FUTURE"),
			};

			if (latestEvents.length) {
				supportData.selectedEvent = _.find(supportData.events, event => event.uidEvent === _.first(latestEvents).uidEvent);
			} else if (!supportData.selectedEvent) {
				//pull the earliest current, future, past - in that order
				if (supportData.groupedEvents["CURRENT"].length) {
					supportData.selectedEvent = supportData.groupedEvents["CURRENT"][0];
				} else if (supportData.groupedEvents["FUTURE"].length) {
					supportData.selectedEvent = supportData.groupedEvents["FUTURE"][0];
				} else if (supportData.groupedEvents["PAST"].length) {
					supportData.selectedEvent = supportData.groupedEvents["PAST"][0];
				} else {
					//leave it undefined, the render process will notice no selectedEvent and will
					//render the input field instead.
				}
			}

			if (_.isFunction(callback)) {
				return callback(supportData);
			}
		}
	}
}

/*
========================================================================================================================
Render Functions
========================================================================================================================
 */
function render_fromDropdown(profiles, selectedProfile) {
	//console.log("render_fromDropdown", { profiles, selectedProfile });
	return tmpl.fromDropdown({ profiles, selectedProfile });
}

function render_fromInput(data) {
	console.log("render_fromInput", data);
	return tmpl.fromInput(data || {});
}

function eventLabel(event) {
	function eventDateLabel(event) {
		const start = moment(event.dteStart);
		const end = moment(event.dteEnd);
		let output = "";
		if (end.year() !== start.year()) {
			output = start.format("MMM D, YYYY");
			output += " - ";
			output += end.format("MMM D, YYYY");
		} else {
			output = start.format("MMM D");
			if (end.date() !== start.date()) {
				output += "-" + end.format("D");
			}
			output += ", " + start.format("YYYY");
		}
		return output;
	}
	return `${event.vchEventName} @ ${event.vchTrackName} (${eventDateLabel(event)})`;
}

function render_eventDropdown(events, groupedEvents, selectedEvent) {
	//console.log("render_eventDropdown", { events, groupedEvents, selectedEvent });
	return tmpl.eventDropdown({ events, groupedEvents, selectedEvent });
}

function render_eventInput(doPrefillEventInput = true) {
	//console.log("render_eventInput");
	let eventURL = "";
	const eventContext = getEventContextOnPage();
	if (doPrefillEventInput && eventContext) {
		eventURL = msr.getBaseURL() + eventContext.vchSlug;
	}
	return tmpl.eventInput({ eventURL });
}

/*
========================================================================================================================
Views
========================================================================================================================
 */

function view_greeting($stage) {
	const data = {
		...greetingData,
		categories: { ...categories },
	};

	if (!includeMsrOption) {
		delete data.categories.TECH_SUPPORT;
	}

	const eventContext = getEventContextOnPage();

	if (eventContext === undefined) {
		data.categories.EVENT_OR_ORG.label = "A question about an event or organization";
	}

	changeView($stage, tmpl.greeting(data), "greeting", data);
}

function view_sendMessage(
	$stage,
	supportData,
	selectedCategory,
	selectedSubCategory,
	helpArticleViewedLink,
	helpArticleViewedTitle,
	previousData = {},
) {
	const selectedCategoryData = getCategoryData(selectedCategory);
	let data = {
		...greetingData,
		...supportData,
		selectedCategory,
		selectedCategoryLabel: selectedCategoryData["label"],
		selectedSubCategory: undefined,
		messageTo: selectedCategoryData["messageTo"],
		helpArticleViewedLink: helpArticleViewedLink || "",
		helpArticleViewedTitle: helpArticleViewedTitle || "",
		...previousData,
	};
	let content = tmpl.categorySelected(data);
	if (selectedSubCategory) {
		const selectedSubCategoryData = getSubCategoryData(selectedCategory, selectedSubCategory);
		data["selectedSubCategory"] = selectedSubCategory;
		data["selectedSubCategoryLabel"] = selectedSubCategoryData["label"];
		data["messageTo"] = selectedSubCategoryData["messageTo"];
		content += tmpl.subCategorySelected(data);
	}
	content += tmpl.sendMessage(data);
	changeView($stage, content, "sendMessage", data);

	const formSet = $stage.find("div.support_funnel-send_message_formset").empty();
	if (PAGE.user.isLoggedIn) {
		formSet.append(render_fromDropdown(data.profiles, selectedProfile));
	} else {
		formSet.append(render_fromInput({fromName: data.fromName, fromEmail: data.fromEmail}));
	}
	if (!data.selectedEvent) {
		formSet.append(render_eventInput());
	} else {
		formSet.append(render_eventDropdown(data.events, data.groupedEvents, supportData.selectedEvent));
	}
	formSet.append(tmpl.messageInput({message: data.message || ""}));
	if (PAGE.user.isLoggedIn) {
		$stage.find("textarea[name='support_funnel-send_message-textarea']").focus();
	} else {
		$stage.find("input[name='support_funnel-send_message-from-fullname']").focus();
	}
}

function view_subQuestions($stage, selectedCategory, isLoggedIn) {
	const data = {
		...greetingData,
		selectedCategory,
		selectedCategoryLabel: getCategoryData(selectedCategory)["label"],
		subCategories: getFilteredSubCategories(selectedCategory, isLoggedIn),
	};
	const initialContent = tmpl.categorySelected(data) + tmpl.loading();
	changeView($stage, initialContent, "subQuestions", data);
	const content = tmpl.categorySelected(data) + tmpl.subQuestions(data);
	setTimeout(() => changeView($stage, content, "subQuestions", data), 300);
}

function view_eventCancellationPolicy($stage, selectedCategory, selectedSubCategory, selectedUidEvent) {
	const selectedSubCategoryData = getSubCategoryData(selectedCategory, selectedSubCategory);
	const data = {
		...greetingData,
		selectedCategory,
		selectedCategoryLabel: getCategoryData(selectedCategory)["label"],
		selectedSubCategory,
		selectedSubCategoryLabel: selectedSubCategoryData["label"],
	};

	const initialContent = tmpl.categorySelected(data) + tmpl.subCategorySelected(data) + tmpl.loading();
	changeView($stage, initialContent, "eventCancellationPolicy", data);

	getEventData(selectedUidEvent, function(eventData) {
		if (eventData) {
			data["eventData"] = eventData;
			const content = tmpl.categorySelected(data) + tmpl.subCategorySelected(data) + tmpl.eventCancellationPolicy(data);
			changeView($stage, content, "eventCancellationPolicy", data);
		} else {
			//we didnt get any data back from the getEventData call, so go on to the send message form
			getSupportData(supportData => view_sendMessage($stage, supportData, selectedCategory, selectedSubCategory));
		}
	});
}

function view_loading($stage) {
	changeView($stage, tmpl.loading(), "submit-loading");
}

function view_success($stage, submitData, submitResult) {
	const data = {
		...greetingData,
		...submitData,
		...submitResult,
	};

	const content = tmpl.success(data);
	changeView($stage, content, "success", data);
}

/*
========================================================================================================================
Controllers
========================================================================================================================
 */

function updateSelectedProfile(e) {
	const target = jq2(e.target).closest("li.support_funnel-send_message-from_dropdown-profile_link");
	const selectedUidMember = target.data("uidmember");

	const profile = _.find(supportData.profiles, p => p.uidMember === selectedUidMember);

	if (!_.isUndefined(profile)) {
		selectedProfile = profile;
		$stage
			.find("div.support_funnel-send_message-from_dropdown")
			.replaceWith(render_fromDropdown(supportData.profiles, selectedProfile));
	}
}

function updateSelectedEvent(e) {
	const target = jq2(e.target).closest("li.support_funnel-send_message-event_dropdown-event_link");
	const selectedUidEvent = target.data("uidEvent".toLowerCase());

	if (selectedUidEvent) {
		const event = _.find(supportData.events, p => p.uidEvent === selectedUidEvent);

		if (!_.isUndefined(event)) {
			supportData.selectedEvent = event;
			$stage
				.find("div.support_funnel-send_message-event_dropdown")
				.replaceWith(render_eventDropdown(supportData.events, supportData.groupedEvents, supportData.selectedEvent));
		}
	} else {
		const action = target.data("action");
		if (action) {
			$stage.find("div.support_funnel-send_message-event_dropdown").replaceWith(render_eventInput(false));

			if (action === "DONT_SEE_EVENT") {
				$stage.find("input[name='support_funnel-send_message-event']").focus();
			} else {
				$stage.find("textarea[name='support_funnel-send_message-textarea']").focus();
			}
		}
	}
}

function categorySelection_change(e) {
	const selectedCategory = this.value || jq2(e.target).data("selectedCategory".toLowerCase());

	fireSupportFunnelEvent("category_selection", { selectedCategory });

	changeView($stage, tmpl.loading(), "loading");

	switch (selectedCategory) {
		case "EVENT_OR_ORG":
			getSupportData(supportData => view_sendMessage($stage, supportData, selectedCategory));
			break;
		case "PAYMENTS":
			view_subQuestions($stage, selectedCategory, greetingData.isLoggedIn);
			break;
		case "CANCEL_OR_REFUND":
			view_subQuestions($stage, selectedCategory, greetingData.isLoggedIn);
			break;
		case "ACCOUNT_RELATED":
			view_subQuestions($stage, selectedCategory, greetingData.isLoggedIn);
			break;
		case "TECH_SUPPORT":
			getSupportData(supportData => view_sendMessage($stage, supportData, selectedCategory));
			break;
	}
}

function subCategorySelection_change() {
	const [selectedCategory, selectedSubCategory] = this.value.split(":");

	fireSupportFunnelEvent("subcategory_selection", { selectedCategory, selectedSubCategory });

	const selectedSubCategoryData = getSubCategoryData(selectedCategory, selectedSubCategory);

	// if there's an article link, open it in a new tab and don't show anything else
	if (selectedSubCategoryData.hasOwnProperty("articleLink")) {
		window.open(selectedSubCategoryData.articleLink, '_blank').focus();
		$(this).attr('checked', false).closest('.panel-select-radio').removeClass('selected')
		return;
	}

	changeView($stage, tmpl.loading(), "loading");

	//handle special cases
	if (selectedSubCategoryData.hasOwnProperty("nextAction")) {
		const nextAction = selectedSubCategoryData["nextAction"];
		if (nextAction === "SHOW_EVENT_CANCELLATION_POLICY") {
			//check to make sure we have specific event context
			const eventContextOnPage = getEventContextOnPage();
			if (eventContextOnPage) {
				return view_eventCancellationPolicy($stage, selectedCategory, selectedSubCategory, eventContextOnPage.uidEvent);
			}
		}
	}

	return getSupportData(supportData => view_sendMessage($stage, supportData, selectedCategory, selectedSubCategory));
}

function close(callbackData) {
	const bsmodal = jq2("#bsmodal-inline");
	let callback = undefined;
	if (bsmodal.length) {
		const data = bsmodal.data();
		if (typeof data["callback"] === "function") {
			callback = data["callback"];
			callback(callbackData);
		}
	}
	parent.jq2(".modal").modal("hide");

	//const eventName = "support_funnel-close";
	//$(document).trigger(eventName, [callbackData]);
	//console.log(eventName, callbackData);

	fireSupportFunnelEvent("close", callbackData);
}

function sendMessageSubmit(e) {
	e.preventDefault();

	const $form = jq2(e.target);

	const data = {
		fromName:
			$stage.find("input[name='support_funnel-send_message-from-fullname']").val() ||
			selectedProfile.vchFirstName + " " + selectedProfile.vchLastName,
		fromEmail: $stage.find("input[name='support_funnel-send_message-from-email']").val() || selectedProfile.vchEmail,
		uidMember: selectedProfile.uidMember,
		homePhone: selectedProfile.homePhone,
		workPhone: selectedProfile.workPhone,
		mobilePhone: selectedProfile.mobilePhone,
		uidEvent: _.get(supportData, "selectedEvent.uidEvent"),
		eventLink: $stage.find("input[name='support_funnel-send_message-event']").val(),
		message: $stage.find("textarea[name='support_funnel-send_message-textarea']").val(),
		selectedCategory: $form.data("selectedCategory".toLowerCase()),
		selectedSubCategory: $form.data("selectedSubCategory".toLowerCase()),
		helpArticleViewedLink: $form.data("helpArticleViewedLink".toLowerCase()),
		helpArticleViewedTitle: $form.data("helpArticleViewedTitle".toLowerCase()),
		messageTo: $form.data("messageTo".toLowerCase()),
		messageSentFromLink: window.location.href,
		clientTime: new Date().toISOString(),
		isLoggedIn: greetingData.isLoggedIn,
		loggedInFullName: greetingData.vchFirstName + " " + greetingData.vchLastName,
		loggedInEmail: greetingData.vchEmail,
		uidLoggedInMember: greetingData.uidMember,
		requestID: PAGE.requestID,
		launchLink: launchLink,
	};

	data.selectedCategoryLabel = (getCategoryData(data.selectedCategory) || {})["label"] || "";
	data.selectedSubCategoryLabel =
		(getSubCategoryData(data.selectedCategory, data.selectedSubCategory) || {})["label"] || "";

	// because it may be possible for us to have preselected an event but
	// they say that none of the options are correct, if they provide a link we should
	// blank out the selected uidEvent.
	// also if they provided an event link, we are going to override and make sure we are
	// sending to MSR
	if (data.eventLink) {
		data.uidEvent = undefined;
		data.messageTo = "MSR";
	}

	fireSupportFunnelEvent("submit", data);

	view_loading($stage);

	supportFunnelSubmit(
		data,
		function(result) {
			view_success($stage, data, result);
		},
		function(errors) {
			console.error(errors);
			getSupportData(supportData =>
				view_sendMessage(
					$stage,
					supportData,
					data.selectedCategory,
					data.selectedSubCategory,
					data.helpArticleViewedLink,
					data.helpArticleViewedTitle,
					{
						fromName: data.fromName,
						fromEmail: data.fromEmail,
						message: data.message,
					},
				),
			);
		},
	);
}

/*
========================================================================================================================
Initialization / Bootstrapping
========================================================================================================================
 */

function installListeners($stage) {
	//set up listeners
	if (typeof window["support_funnel_document_listeners_installed"] === "undefined") {
		jq2(document).on("support_funnel", handleSupportFunnelEvent);
		jq2("#bsmodal-inline").on("hidden.bs.modal.support_funnel", function() {
			if (msr.bsmodalIsVisible()) {
				close({ type: "manual_close" });
			}
		});
		window["support_funnel_document_listeners_installed"] = true;
	}
	$stage.on("change.support_funnel", "input[name='support_funnel-category_selection']", categorySelection_change);
	$stage.on(
		"change.support_funnel",
		"input[name='support_funnel-sub_category_selection']",
		subCategorySelection_change,
	);
	$stage.on("click.support_funnel", "a.support_funnel-start_over", () => {
		fireSupportFunnelEvent("start_over", {});
		view_greeting($stage);
	});
	$stage.on("click.support_funnel", "a.support_funnel-start_over_category", categorySelection_change);
	$stage.on("click.support_funnel", "a.support_flow-cancel", () => {
		fireSupportFunnelEvent("cancel", {});
		close({ type: "cancel" });
	});
	$stage.on("click.support_funnel", "a.support_flow-doc_helped", e => {
		const selectedCategory = jq2(e.target).data("selectedCategory".toLowerCase());
		const selectedSubCategory = jq2(e.target).data("selectedSubCategory".toLowerCase());
		const helpArticleViewedLink = jq2(e.target).data("helpArticleViewedLink".toLowerCase());
		const helpArticleViewedTitle = jq2(e.target).data("helpArticleViewedTitle".toLowerCase());
		const uidEvent = jq2(e.target).data("uidEvent".toLowerCase());

		fireSupportFunnelEvent("doc_helped", {
			selectedCategory,
			selectedSubCategory,
			helpArticleViewedTitle,
			helpArticleViewedLink,
			uidEvent,
		});
		close({ type: "success" });
	});
	$stage.on("click.support_funnel", "a.support_flow-doc_did_not_help", e => {
		const selectedCategory = jq2(e.target).data("selectedCategory".toLowerCase());
		const selectedSubCategory = jq2(e.target).data("selectedSubCategory".toLowerCase());
		const helpArticleViewedLink = jq2(e.target).data("helpArticleViewedLink".toLowerCase());
		const helpArticleViewedTitle = jq2(e.target).data("helpArticleViewedTitle".toLowerCase());
		const uidEvent = jq2(e.target).data("uidEvent".toLowerCase());

		fireSupportFunnelEvent("doc_did_not_help", {
			selectedCategory,
			selectedSubCategory,
			helpArticleViewedTitle,
			helpArticleViewedLink,
			uidEvent,
		});

		getSupportData(supportData =>
			view_sendMessage(
				$stage,
				supportData,
				selectedCategory,
				selectedSubCategory,
				helpArticleViewedLink,
				helpArticleViewedTitle,
			),
		);
	});
	$stage.on("click.support_funnel", "a.support_flow-success-close_and_continue", () => {
		close({ type: "success" });
	});
	$stage.on("submit.support_funnel", "form.support_funnel-send_message-form", sendMessageSubmit);
	$stage.on("click.support_funnel", "li.support_funnel-send_message-from_dropdown-profile_link", updateSelectedProfile);
	$stage.on("click.support_funnel", "li.support_funnel-send_message-event_dropdown-event_link", updateSelectedEvent);
}

jq2(document).on("bsmodal-launch", function(e, args) {
	if (args.options !== undefined && args.options.div !== undefined && args.options.div === "#modal-support-content") {
		supportFlowSessionId = new Date().getTime();
		includeMsrOption = Boolean(args.options.supportIncludeMsrOption)
		launchLink = args.options.launchLink
		$stage = jq2("#bsmodal-inline #support_funnel-stage");

		installListeners($stage);

		fireSupportFunnelEvent("launch", {
			isLoggedIn: greetingData.isLoggedIn,
			currentPage: window.location.href,
			launchLink: launchLink,
		});

		//initial greeting
		view_greeting($stage);
	}
});

jq2(document).ready(function() {
	tmpl.greetingText = compileTemplate("support_funnel-greeting_text");
	tmpl.greeting = compileTemplate("support_funnel-greeting");
	tmpl.loading = compileTemplate("support_funnel-loading");
	tmpl.categorySelected = compileTemplate("support_funnel-category_selected");
	tmpl.subCategorySelected = compileTemplate("support_funnel-subCategory_selected");
	tmpl.sendMessage = compileTemplate("support_funnel-send_message");
	tmpl.fromDropdown = compileTemplate("support_funnel-send_message-from_dropdown");
	tmpl.fromInput = compileTemplate("support_funnel-send_message-from_input");
	tmpl.eventDropdown = compileTemplate("support_funnel-send_message-event_dropdown");
	tmpl.eventInput = compileTemplate("support_funnel-send_message-event_input");
	tmpl.messageInput = compileTemplate("support_funnel-send_message-message_input");
	tmpl.subQuestions = compileTemplate("support_funnel-sub_questions");
	tmpl.eventCancellationPolicy = compileTemplate("support_funnel-event_cancellation_policy");
	tmpl.success = compileTemplate("support_funnel-success");

	storeCurrentEvent();
});

msr.msrForms();
